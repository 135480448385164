import { FC, useState } from "react";

import { HStack, Stack, Box, useMultiStyleConfig } from "@chakra-ui/react";
import { TFunction, useTranslation } from "next-i18next";

import Link from "@components/Link";
import responsiveStyle from "@lib/theme/responsiveStyle";
import { checkIfCookieFeatureEnabled, CookieModals } from "@modules/cookie-consent";
import { useFeatureData } from "@modules/features";
import { ROUTES } from "@utils/constants";

import { LayoutContent } from "../Layout";
import { DpdGroupLogo } from "../Logo";

const getConfig = (t: TFunction) => [
  { id: "footer-contacts", name: t("layout-footer-contacts"), path: t("layout-footer-contacts-href"), external: true },
  {
    id: "footer-conditions",
    name: t("layout-footer-terms_and_conditions"),
    path: t("layout-footer-terms_and_conditions-href"),
    external: true,
  },
  {
    id: "footer-policy",
    name: t("layout-footer-privacy_policy"),
    path: t("layout-footer-privacy_policy-href"),
    external: true,
  },
  { id: "footer-faq", name: t("layout-footer-faq"), path: ROUTES.FAQ, external: false },
];

const Footer: FC = () => {
  const styles = useMultiStyleConfig("Footer", {});
  const { t } = useTranslation();
  const [isCookieModalOpen, setIsCookieModalOpen] = useState(false);
  const onSettingsCookieModalClose = () => setIsCookieModalOpen(false);
  const features = useFeatureData();
  const isCookieFeatureEnabled = checkIfCookieFeatureEnabled(features);

  return (
    <Box sx={styles.section}>
      <LayoutContent sx={styles.wrapper}>
        <HStack sx={styles.container}>
          <Stack
            direction={responsiveStyle({ mobile: "column", desktop: "row" })}
            spacing={responsiveStyle({ mobile: "0.75rem", desktop: "2.375rem" })}
          >
            {getConfig(t).map(({ id, name, path, external }) => (
              <Link id={id} label={name} href={path} key={id} sx={styles.text} isExternal={external} />
            ))}
            {isCookieFeatureEnabled && (
              <>
                <Link
                  id="footer-change-cookie-settings"
                  label={t("layout-footer-cookie_change_settings")}
                  key="footer-change-cookie-settings"
                  sx={styles.text}
                  isExternal={false}
                  onClick={() => {
                    setIsCookieModalOpen(true);
                  }}
                />
                <CookieModals
                  isSettingsModalOpen={isCookieModalOpen}
                  onSettingsModalClose={onSettingsCookieModalClose}
                />
              </>
            )}
          </Stack>
          <Box sx={styles.imageContainer}>
            <DpdGroupLogo />
          </Box>
        </HStack>
      </LayoutContent>
    </Box>
  );
};

export default Footer;
