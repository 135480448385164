import { cloneElement, isValidElement, FC } from "react";

import { chakra, HTMLChakraProps } from "@chakra-ui/react";

const LinkIcon: FC<HTMLChakraProps<"span">> = ({ children, ...rest }) => (
  <chakra.span flexShrink={0} {...rest}>
    {isValidElement(children)
      ? cloneElement(children, {
          "aria-hidden": true,
          focusable: false,
        })
      : children}
  </chakra.span>
);
export default LinkIcon;
