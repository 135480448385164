import { FC } from "react";

import dynamic from "next/dynamic";

import type { TCustomIconProps } from "./types";

export const iconList = {
  calendar: dynamic(() => import("./icons/CalendarIcon")),
  checkmark: dynamic(() => import("./icons/CheckmarkIcon")),
  checkmarkReverse: dynamic(() => import("./icons/CheckmarkReverseIcon")),
  checkmarkThickIcon: dynamic(() => import("./icons/CheckmarkThickIcon")),
  chevronDown: dynamic(() => import("./icons/ChevronDownIcon")),
  chevronUp: dynamic(() => import("./icons/ChevronUpIcon")),
  chevronLeft: dynamic(() => import("./icons/ChevronLeftIcon")),
  chevronRight: dynamic(() => import("./icons/ChevronRightIcon")),
  clock: dynamic(() => import("./icons/ClockIcon")),
  close: dynamic(() => import("./icons/CloseIcon")),
  closeRed: dynamic(() => import("./icons/CloseRedIcon")),
  delivery: dynamic(() => import("./icons/DeliveryIcon")),
  deliveryRedirect: dynamic(() => import("./icons/DeliveryRedirectIcon")),
  download: dynamic(() => import("./icons/DownloadIcon")),
  edit: dynamic(() => import("./icons/EditIcon")),
  email: dynamic(() => import("./icons/EmailIcon")),
  export: dynamic(() => import("./icons/ExportIcon")),
  exportMono: dynamic(() => import("./icons/ExportMonoIcon")),
  external: dynamic(() => import("./icons/ExternalIcon")),
  eraser: dynamic(() => import("./icons/EraserIcon")),
  facebook: dynamic(() => import("./icons/FacebookIcon")),
  filters: dynamic(() => import("./icons/FiltersIcon")),
  info: dynamic(() => import("./icons/InfoIcon")),
  infoThin: dynamic(() => import("./icons/InfoThinIcon")),
  location: dynamic(() => import("./icons/Location")),
  logout: dynamic(() => import("./icons/LogoutIcon")),
  magnifier: dynamic(() => import("./icons/MagnifierIcon")),
  menuBurger: dynamic(() => import("./icons/MenuBurgerIcon")),
  minus: dynamic(() => import("./icons/MinusIcon")),
  pickup: dynamic(() => import("./icons/PickupIcon")),
  pickupStation: dynamic(() => import("./icons/PickupStationIcon")),
  pinCode: dynamic(() => import("./icons/PinCodeIcon")),
  profile: dynamic(() => import("./icons/ProfileIcon")),
  profileThin: dynamic(() => import("./icons/ProfileThinIcon")),
  secretHide: dynamic(() => import("./icons/SecretHideIcon")),
  secretReveal: dynamic(() => import("./icons/SecretRevealIcon")),
  stop: dynamic(() => import("./icons/StopIcon")),
  success: dynamic(() => import("./icons/SuccessIcon")),
  telephone: dynamic(() => import("./icons/TelephoneIcon")),
  trashBin: dynamic(() => import("./icons/TrashBinIcon")),
  warning: dynamic(() => import("./icons/WarningIcon")),
  weight: dynamic(() => import("./icons/WeightIcon")),
  plus: dynamic(() => import("./icons/PlusIcon")),
};

const Icon: FC<TCustomIconProps> = ({ icon, size, ...props }) => {
  const Component = iconList[icon];
  if (!Component) {
    throw new Error(`Icon "${icon}" does not exist in the icon list`);
  }

  return <Component {...props} height={size} width={size} />;
};

export default Icon;
