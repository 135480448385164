/* eslint-disable promise/prefer-await-to-then */
import { FC } from "react";

import dynamic from "next/dynamic";

import { useLocales } from "@lib/custom-locales";
import getEnv from "@utils/getEnv";
import languageMap from "@utils/languageMap";

import getRecaptchaKey from "./getRecaptchaKey";

const GoogleReCaptchaProvider = dynamic<any>(() =>
  import("react-google-recaptcha-v3").then(({ GoogleReCaptchaProvider: Component }) => Component)
);

type TRecaptchaWrapperProps = {
  isEnabled: boolean;
  domain?: string;
};

const RecaptchaProvider: FC<TRecaptchaWrapperProps> = ({ children, isEnabled, domain }) => {
  const locales = useLocales();

  const recaptchaKey = getRecaptchaKey(domain);

  /**
   * If locales aren't loaded, app throws an error elsewhere.
   */
  if (!isEnabled || !locales || !recaptchaKey) {
    return <>{children}</>;
  }

  return (
    <GoogleReCaptchaProvider
      language={languageMap[locales.customLocale]}
      reCaptchaKey={recaptchaKey}
      useRecaptchaNet={getEnv("NEXT_PUBLIC_RECAPTCHA_USE_NET") === "true"}
      useEnterprise={getEnv("NEXT_PUBLIC_RECAPTCHA_USE_ENTERPRISE") === "true"}
      scriptProps={{
        async: false,
        defer: true,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default RecaptchaProvider;
