import { FC } from "react";

import { useTranslation } from "next-i18next";

import { Typography } from "@components/common";
import Expand from "@components/Expand";

const expandHeaderStyle = { color: "red.lead", fontSize: "0.875rem", _hover: { textDecoration: "underline" } };

const CookieConsentExpand: FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Expand
      renderHeader={(isOpen) => (
        <Typography sx={expandHeaderStyle}>
          {isOpen ? t("cookie_consent-settings-list_hide") : t("cookie_consent-settings-list_show")}
        </Typography>
      )}
    >
      {children}
    </Expand>
  );
};

export default CookieConsentExpand;
