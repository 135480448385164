import { FC } from "react";

import { Checkbox as CheckboxBase, FormControl, Box } from "@chakra-ui/react";

import { FormFieldLabel, FormMessage, FormHint } from "@components/common";
import withFormikField from "@lib/withFormikField";

import { TCheckboxProps } from "./types";

const Checkbox: FC<TCheckboxProps> = ({
  name,
  children,
  defaultIsChecked = false,
  isFormik,
  isInvalid,
  isDisabled,
  isRequired,
  label,
  message,
  hint,
  size = "md",
  spacing = "0.625rem",
  ...rest
}) => (
  <FormControl isDisabled={isDisabled} isInvalid={isInvalid}>
    <FormFieldLabel label={label} />
    <CheckboxBase
      defaultIsChecked={defaultIsChecked}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isRequired={isRequired}
      spacing={spacing}
      size={size}
      name={name}
      {...rest}
    >
      {children && <Box verticalAlign="top">{children}</Box>}
    </CheckboxBase>
    <FormHint hint={hint} />
    {isInvalid && <FormMessage message={message} isInvalid />}
  </FormControl>
);

export default withFormikField(Checkbox);
