import { FC, useCallback, useContext, useEffect } from "react";

import { MessageContext } from "./context";
import useMessage from "./hooks/useMessage";
import Message from "./Message";
import type { TContextMessageProps } from "./types";

const ContextMessage: FC<TContextMessageProps> = ({ messageZone, autoClearMs: zoneAutoClearMs, ...otherProps }) => {
  const { message, props, messageNo, zone, autoClearMs } = useContext(MessageContext);

  const setMessage = useMessage();

  // If message has a zone - clearing all message when message component unmounts
  // So there won't be visible message when user opens any other page and comes back here
  useEffect(
    () => () => {
      if (messageZone) {
        setMessage({ message: "", props: {}, zone: messageZone });
      }
    },
    [setMessage, messageZone]
  );

  useEffect(() => {
    if (autoClearMs || zoneAutoClearMs) {
      const timeout = setTimeout(
        () => setMessage({ message: "", props: {}, zone: messageZone }),
        autoClearMs || zoneAutoClearMs
      );
      return () => clearTimeout(timeout);
    }

    // Added an empty callback to avoid ESlint message for inconsistent returns
    return () => {};
  }, [setMessage, autoClearMs, zoneAutoClearMs, messageZone]);

  // Clearing message after close, so it won't popup again after rerender
  const handleClose = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    }
    if (messageZone) {
      setMessage({ message: "", props: {}, zone: messageZone });
    }
  }, [messageZone, props, setMessage]);

  if (!message || (messageZone && zone !== messageZone)) {
    return null;
  }

  return (
    <Message key={`context-message-${messageNo}`} {...{ ...otherProps, ...props }} onClose={handleClose}>
      {message}
    </Message>
  );
};

export default ContextMessage;
