import { forwardRef } from "react";

import { Button as ButtonBase } from "@chakra-ui/react";

import type { TIconButtonProps } from "./types";

const IconButton = forwardRef<HTMLButtonElement, TIconButtonProps>(
  ({ icon, size = "md", iconSpacing = "0", isOutlined = false, variant, ...rest }, ref) => {
    const isRedOutline = variant === "transparent-red" || variant === "grey-red";
    const shouldOutline = isOutlined || isRedOutline;
    return (
      <ButtonBase
        leftIcon={icon}
        size={`icon-${size}`}
        iconSpacing={iconSpacing}
        variant={variant}
        ref={ref}
        {...(shouldOutline
          ? {
              border: "1px solid",
              borderColor: isRedOutline ? "red.lead" : "grey.mid",
              _disabled: {
                borderColor: "grey.mid",
              },
            }
          : { border: "0px solid" })}
        {...rest}
      />
    );
  }
);

IconButton.displayName = "IconButton";
export default IconButton;
